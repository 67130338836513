<app-modal-header (iconClicked)="close()" dataCy="login-as-cancel-icon-button">{{
  'login-as' | translate
}}</app-modal-header>

<div class="content">
  <app-user-impersonable-single-select
    dataCy="login-as-single-select"
    [control]="control"
    label="first-and-last-name"
  ></app-user-impersonable-single-select>
</div>

<app-modal-buttons-group>
  <button pso-ghost-button dataCy="login-as-cancel-button" (click)="close()">
    {{ 'cancel' | translate | uppercase }}
  </button>
  <button pso-button dataCy="login-as-button" [disabled]="isLoading" (click)="confirm()" color="primary">
    {{ 'login' | translate | uppercase }}
  </button>
</app-modal-buttons-group>
