import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { PsoBaseButtonComponent } from '@app/@pso-ui/button-pso/pso-base-button.component';
import { PsoColor } from '@app/@wchfs-ui/lib/colors/colors.model';

@Component({
  selector: 'button[pso-ghost-button]',
  templateUrl: './pso-ghost-button.component.html',
  styleUrls: ['../pso-base-button.component.scss', './pso-ghost-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PsoGhostButtonComponent extends PsoBaseButtonComponent {
  @HostBinding('style.backgroundColor')
  get styleBackgroundColor(): string {
    return this.disabled ? `var(--ui-g5-gray5)` : this.color !== 'transparent' ? `var(--${this.color}` : 'transparent';
  }

  @HostBinding('style.color')
  get styleColor(): string {
    return this.disabled ? `var(--primary-w-white)` : `var(--${this.textColor})`;
  }

  @Input()
  color: PsoColor | 'transparent' = 'transparent';

  @Input()
  textColor: PsoColor = 'primary-bg-blue-grey';
}
