<div class="wchfs-form-field-wrapper">
  <label class="wchfs-form-field-label">
    <ng-content select="wchfs-label"></ng-content>
  </label>

  <span #wchfsPrefix class="wchfs-form-field-prefix">
    <ng-content select="[wchfsPrefix]"></ng-content>
  </span>

  <div #wchfsInfix class="wchfs-form-field-infix">
    <ng-content></ng-content>
  </div>

  <span class="wchfs-form-field-errors">
    <ng-content select="wchfs-validation-error"></ng-content>
  </span>

  <span class="wchfs-form-field-hint">
    <ng-content select="wchfs-hint"></ng-content>
  </span>

  <div class="wchfs-form-field-suffix">
    <ng-content select="[wchfsSuffix]"></ng-content>
  </div>

  <div #maskWithDynamicPosition *ngIf="!!inputValue" class="wchfs-form-field-suffix wchfs-mask">
    <ng-content select="[wchfsAutoLeftMask]"></ng-content>
  </div>

  <div #maskEnd class="wchfs-form-field-suffix wchfs-mask wchfs-mask-end">
    <ng-content select="[wchfsMaskTypeEnd]"></ng-content>
  </div>

  <div class="wchfs-form-field-toggle">
    <ng-content select="wchfs-datepicker-toggle"></ng-content>
  </div>

  <div class="wchfs-form-field-info-toggle">
    <ng-content select="wchfs-info-tooltip-toggle"></ng-content>
  </div>
</div>
