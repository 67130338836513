import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PsoButtonComponent } from './pso-button/pso-button.component';
import { ButtonRippleDirective } from '@app/@pso-ui/button-pso/ripple/ripple';
import { PsoGhostButtonComponent } from './pso-ghost-button/pso-ghost-button.component';
import { PsoBaseButtonComponent } from './pso-base-button.component';
import { SvgIconModule } from '@app/@wchfs-ui';
import { DotLoaderModule } from '../dot-loader/dot-loader.module';
import { PsoIconButtonComponent } from './pso-icon-button/pso-icon-button.component';

@NgModule({
  declarations: [
    PsoButtonComponent,
    ButtonRippleDirective,
    PsoGhostButtonComponent,
    PsoBaseButtonComponent,
    PsoIconButtonComponent,
  ],
  imports: [CommonModule, SvgIconModule, DotLoaderModule],
  exports: [PsoButtonComponent, PsoGhostButtonComponent, PsoIconButtonComponent],
})
export class PsoButtonModule {}
