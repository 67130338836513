import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { PsoBaseButtonComponent } from '@app/@pso-ui/button-pso/pso-base-button.component';
import { PsoColor } from '@app/@wchfs-ui/lib/colors/colors.model';

@Component({
  selector: 'button[pso-button]',
  templateUrl: './pso-button.component.html',
  styleUrls: ['../pso-base-button.component.scss', './pso-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PsoButtonComponent extends PsoBaseButtonComponent {
  @HostBinding('style.backgroundColor')
  get styleBackgroundColor(): string {
    return this.disabled ? `var(--ui-g5-gray5)` : `var(--${this.color})`;
  }

  @Input()
  color: PsoColor;
}
