import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { asyncScheduler, of } from 'rxjs';
import {
  INITIAL_AUTO_MASK_POSITION,
  PADDING_LEFT_FOR_AUTO_MASK,
  PADDING_LEFT_FOR_HINT,
  PADDING_RIGHT_FOR_AUTO_MASK,
  PADDING_RIGHT_SPACE_WITH_HINT,
  PADDING_RIGHT_WITH_MASK_END,
} from './form-field.constants';
import { FocusMonitor } from '@angular/cdk/a11y';

@Component({
  selector: 'wchfs-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
})
export class FormFieldComponent implements OnInit, AfterViewInit, OnDestroy {
  inputValue: string;
  inputContentWidth: number;
  @Input() contentOnRight = false;
  @Input() isLeftSuffixAutoPosition: any;
  @ViewChild('wchfsPrefix') wchfsPrefix: ElementRef;
  @ViewChild('wchfsInfix') infix: ElementRef;
  @ViewChild('maskWithDynamicPosition') maskWithDynamicPosition: ElementRef;
  @ViewChild('maskEnd') maskEnd: ElementRef;
  @HostBinding('class.wchfs-input-right') isWchfsPrefix = false;
  private hintText: string;

  constructor(
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private fm: FocusMonitor,
    private elementRef: ElementRef<HTMLElement>
  ) {}

  @Input() set hintOnFocus(text: string) {
    this.hintText = text;
    this.fm.monitor(this.elementRef.nativeElement, true).subscribe((origin) => {
      if (!!origin) {
        this.createHint();
        this.adjustPaddingToHint();
      } else {
        this.removeHint();
      }
    });
  }

  @HostListener('input', ['$event']) triggerEsc(event: Event) {
    const targetElement = event.target as HTMLInputElement;
    this.inputValue = targetElement.value;
    this.cdr.detectChanges();
    const length = targetElement.value.length;
    const potentialPosition = INITIAL_AUTO_MASK_POSITION + length * 8;
    if (!!this.maskWithDynamicPosition) {
      const newPosition = potentialPosition > this.inputContentWidth ? this.inputContentWidth : potentialPosition;
      this.renderer.setStyle(this.maskWithDynamicPosition.nativeElement, 'right', `${newPosition}px`);
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (this.isLeftSuffixAutoPosition) {
      this.inputContentWidth = this.infix.nativeElement.offsetWidth - 40;
      this.adjustPaddingToInput(PADDING_LEFT_FOR_AUTO_MASK, PADDING_RIGHT_FOR_AUTO_MASK);
    } else if (!!this.hintText) {
      this.adjustPaddingToInput(PADDING_LEFT_FOR_HINT, PADDING_RIGHT_FOR_AUTO_MASK);
    } else if (!!this.maskEnd.nativeElement.children.length) {
      this.renderer.setStyle(this.infix.nativeElement.children[0], 'padding-right', `${PADDING_RIGHT_WITH_MASK_END}px`);
    }

    of('', asyncScheduler).subscribe(() => {
      this.isWchfsPrefix = this.contentOnRight || !!this.wchfsPrefix.nativeElement.children.length;
      this.cdr.markForCheck();
    });
  }

  ngOnDestroy() {
    this.fm.stopMonitoring(this.elementRef.nativeElement);
  }

  private adjustPaddingToHint() {
    const hintWidth = this.elementRef.nativeElement.querySelector('.hint-on-focus').clientWidth;
    this.renderer.setStyle(
      this.infix.nativeElement.children[0],
      'padding-right',
      `${hintWidth + PADDING_RIGHT_SPACE_WITH_HINT}px`
    );
  }

  private removeHint() {
    this.renderer.removeChild(this.maskEnd.nativeElement, this.maskEnd.nativeElement.children[0]);
    this.renderer.removeClass(this.infix.nativeElement, 'input-with-hint');
    this.adjustPaddingToInput(PADDING_LEFT_FOR_HINT, PADDING_RIGHT_FOR_AUTO_MASK);
  }

  private createHint() {
    const hintContainer = this.renderer.createElement('span');
    this.renderer.addClass(hintContainer, 'hint-on-focus');
    this.renderer.addClass(this.infix.nativeElement, 'input-with-hint');
    const hintText = this.renderer.createText(this.hintText);

    this.renderer.appendChild(hintContainer, hintText);
    this.renderer.appendChild(this.maskEnd.nativeElement, hintContainer);
  }

  private adjustPaddingToInput(paddingLeft: number, paddingRight: number) {
    this.renderer.setStyle(this.infix.nativeElement.children[0], 'padding-left', `${paddingLeft}px`);
    this.renderer.setStyle(this.infix.nativeElement.children[0], 'padding-right', `${paddingRight}px`);
  }
}
